import React from 'react';
import { makeStyles } from '@material-ui/styles';

import styles from './Labels.styles';

const useStyles = makeStyles(theme => styles(theme));

export default function Labels({ status, featured }) {
  const classes = useStyles();
  if (!status && !featured) return null;

  const statuses = [
    { name: 'New', class: classes.CardLabelNew },
    { name: 'Coming soon', class: classes.CardLabelComingSoon },
    { name: 'Recently let', class: classes.CardLabelRecentlyLet },
    { name: 'To be discussed', class: classes.CardLabelToBeDiscussed },
    { name: 'Under offer', class: classes.CardLabelUnderOffer },
    { name: 'Investment planned', class: classes.CardLabelInvestmentPlanned },

    { name: 'Tenancy', class: classes.CardLabelTenancy },
    { name: 'Operator Partnership', class: classes.CardLabelOperatorPartnership },
  ]

  return <div className={`${classes.CardLabels} ${featured ? classes.CardLabelsFeatured : ''}`}>
    {statuses.map(possibleStatus => {
      if (status?.includes(possibleStatus.name)) {
        return <span key={possibleStatus.name} className={`$(classes.CardLabel) ${possibleStatus.class}`}>{possibleStatus.name}</span>
      }
    })}
    { featured && <span className={`${classes.CardLabel} ${classes.CardLabelFeatured}`}>Featured</span>}
  </div>
}
