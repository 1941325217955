import React, { useState } from 'react'; // eslint-disable-line
import { makeStyles } from '@material-ui/styles';

import styles from './Filter.styles';
import arrowIcon from '../../images/right-arrow.png';
import foodIconLight from '../../images/white-icon-food.png';
import accomIconLight from '../../images/white-icon-stay.png';
import dogIconLight from '../../images/white-icon-dog-friendly.png';
import gardenIconLight from '../../images/white-icon-beer-garden.png';
import foodIconDark from '../../images/icon-food.png';
import accomIconDark from '../../images/icon-stay.png';
import dogIconDark from '../../images/icon-dog-friendly.png';
import gardenIconDark from '../../images/icon-beer-garden.png';
import tenancyIconDark from '../../images/icon-tenancy.png';
import tenancyIconLight from '../../images/icon-tenancy-light.png';
import operatorPartnershipDark from '../../images/icon-operator-partnership.png';
import operatorPartnershipLight from '../../images/icon-operator-partnership-light.png';

import newIconLight from '../../images/white-new.png';
import newIconDark from '../../images/new.png';
import comingSoonIconLight from '../../images/white-coming-soon.png';
import comingSoonIconDark from '../../images/coming-soon.png';
import recentlyLetIconLight from '../../images/white-recently-let.png';
import recentlyLetIconDark from '../../images/recently-let.png';
import toBeDiscussedIconLight from '../../images/white-to-be-discussed.png';
import toBeDiscussedIconDark from '../../images/to-be-discussed.png';
import underOfferIconLight from '../../images/white-under-offer.png';
import underOfferIconDark from '../../images/under-offer.png';
import investmentPlannedIconLight from '../../images/white-investment-planned.png';
import investmentPlannedIconDark from '../../images/investment-planned.png';

const useStyles = makeStyles(theme => styles(theme));

export default function Filter({ showPubs }) {
  const classes = useStyles();

  const [county, updateCounty] = useState();
  const [town, updateTown] = useState();
  const [name, updateName] = useState();
  const [radius, updateRadius] = useState('5');
  const [requireFood, updateFood] = useState(false);
  const [requireAccommodation, updateAccommodation] = useState(false);
  const [requireDogFriendly, updateDogs] = useState(false);
  const [requireBeerGarden, updateBeer] = useState(false);
  const [requireNew, updateNew] = useState(false);
  const [requireOffer, updateOffer] = useState(false);
  const [requireComing, updateComing] = useState(false);
  const [requireRecentlyLet, updateRecentlyLet] = useState(false);
  const [requireToBeDiscussed, updateToBeDiscussed] = useState(false);
  const [requireInvestmentPlanned, updateInvestmentPlanned] = useState(false);
  const [requireTenancyAgreementType, updateTenancyAgreementType] = useState();
  const [agreementTypeCollapsed, setAgreementTypeCollapse] = useState(false);
  const [facilitiesCollapsed, setFacilitiesCollapse] = useState(false);
  const [statusCollapsed, setStatusCollapse] = useState(true);

  const [tenancyAgreementTypeTenancy, setTenancyAgreementTypeTenancy] = useState(false);
  const [tenancyAgreementTypeOperatorPartnership, setTenancyAgreementTypeOperatorPartnership] = useState(false);

  function updateTenancyAgreementTypeTenancy(target) {
    updateTenancyAgreementType(target.checked ? target.value : null);
    setTenancyAgreementTypeTenancy(target.checked);
    setTenancyAgreementTypeOperatorPartnership(false);
  }

  function updateTenancyAgreementTypeOperatorPartnership(target) {
    updateTenancyAgreementType(target.checked ? target.value : null);
    setTenancyAgreementTypeOperatorPartnership(target.checked);
    setTenancyAgreementTypeTenancy(false);
  }

  function handleSubmit(e) {
      e.preventDefault();

      const data = {
        county,
        town,
        name,
        radius,
        requireFood,
        requireAccommodation,
        requireDogFriendly,
        requireBeerGarden,
        requireNew,
        requireOffer,
        requireComing,
        requireRecentlyLet,
        requireToBeDiscussed,
        requireInvestmentPlanned,
        requireTenancyAgreementType,
      }

      showPubs(data);
  }

  return <form className={classes.Form} onSubmit={handleSubmit}>
    <div className={classes.CountyWrapper}>
      <label htmlFor="County" className={classes.Label}>County</label>
      <select name="County" className={`${classes.Input} ${classes.CountyList}`} placeholder="County" onChange={(e) => updateCounty(e.target.value)} defaultValue="">
        <option value="">County</option>
        <option value="Anglesey">Anglesey</option>
        <option value="Cheshire">Cheshire</option>
        <option value="Clwyd">Clwyd</option>
        <option value="Conwy">Conwy</option>
        <option value="Cumbria">Cumbria</option>
        <option value="Derbyshire">Derbyshire</option>
        <option value="Greater Manchester">Greater Manchester</option>
        <option value="Gwynedd">Gwynedd</option>
        <option value="Lancashire">Lancashire</option>
        <option value="Manchester">Manchester</option>
        <option value="Merseyside">Merseyside</option>
        <option value="North Yorkshire">North Yorkshire</option>
        <option value="Shropshire">Shropshire</option>
        <option value="Staffordshire">Staffordshire</option>
        <option value="West Yorkshire">West Yorkshire</option>
      </select>
    </div>

    <label htmlFor="Towns" className={classes.Label}>Towns</label>
    <input name="Towns" className={classes.Input} placeholder="Towns" onChange={(e) => updateTown(e.target.value)} />

    <label htmlFor="Radius" className={classes.Label}>Radius</label>

    <select className={`${classes.Input} ${classes.Radius}`}
      name="radius" value={radius ?? 5}
      onBlur={(e) => updateRadius(e.target.value)}
      onChange={(e) => updateRadius(e.target.value)}>
        { [5, 10, 15, 20, 25, 50, 100].map((r, i) => <option value={r} key={i}>{`${r} Miles`}</option>) }
    </select>

    <label className={classes.Label} htmlFor="name">Search</label>
    <input className={classes.Input}
          type="search"
          name="name"
          placeholder="Pub Name"
          autoComplete="off"
          onChange={(e) => updateName(e.target.value)}
    />

    <label className={classes.FilterHeading} htmlFor="AgreementType" onClick={() => setAgreementTypeCollapse(!agreementTypeCollapsed)} data-open={!agreementTypeCollapsed}>Filter by Agreement</label>
    <div className={`${classes.FacilitiesOptions} ${agreementTypeCollapsed && classes.OptionsCollapsed}`}>
      <label className={classes.Option}>
        <input type="checkbox" name="tenancy_agreement_type_tenancy" value="Tenancy" checked={tenancyAgreementTypeTenancy} onChange={(e) => updateTenancyAgreementTypeTenancy(e.target)} />
        <span className="checkmark"></span>
        <img src={tenancyIconLight} alt="" className="light" />
        <img src={tenancyIconDark} alt="" className="dark" />
        Tenancy
      </label>
      <label className={classes.Option}>
        <input type="checkbox" name="tenancy_agreement_type_operator_partnership" value="Operator Partnership" checked={tenancyAgreementTypeOperatorPartnership} onChange={(e) => updateTenancyAgreementTypeOperatorPartnership(e.target)} />
        <span className="checkmark"></span>
        <img src={operatorPartnershipLight} alt="" className="light" />
        <img src={operatorPartnershipDark} alt="" className="dark" />
        Operator Partnership
      </label>
    </div>

    <label className={classes.FilterHeading} htmlFor="Facilities" onClick={() => setFacilitiesCollapse(!facilitiesCollapsed)} data-open={!facilitiesCollapsed}>Filter by Facilities</label>
    <div className={`${classes.FacilitiesOptions} ${facilitiesCollapsed && classes.OptionsCollapsed}`}>
      <label className={classes.Option}>
          <input type="checkbox" name="food" value="food" onChange={(e) => updateFood(e.target.checked)}/>
          <span className="checkmark"></span>
          <img src={foodIconLight} alt="" className="light"/>
          <img src={foodIconDark} alt="" className="dark"/>
          Food
      </label>
      <label className={classes.Option}>
          <input type="checkbox" name="accommodation" value="accommodation" onChange={(e) => updateAccommodation(e.target.checked)} />
          <span className="checkmark"></span>
          <img src={accomIconLight} alt="" className="light" />
          <img src={accomIconDark} alt="" className="dark" />
          Accommodation
      </label>
      <label className={classes.Option}>
          <input type="checkbox" name="dog_friendly" value="dogs" onChange={(e) => updateDogs(e.target.checked)} />
          <span className="checkmark"></span>
          <img src={dogIconLight} alt="" className="light" />
          <img src={dogIconDark} alt="" className="dark" />
          Dog Friendly
      </label>
      <label className={classes.Option}>
          <input type="checkbox" name="beer_garden" value="beer" onChange={(e) => updateBeer(e.target.checked)} />
          <span className="checkmark"></span>
          <img src={gardenIconLight} alt="" className="light" />
          <img src={gardenIconDark} alt="" className="dark" />
          Beer Garden
      </label>
    </div>

    <hr className={classes.divider} />

    <label className={classes.FilterHeading} htmlFor="Status" onClick={() => setStatusCollapse(!statusCollapsed)} data-open={!statusCollapsed}>Filter by Status</label>
    <div className={`${classes.FacilitiesOptions} ${statusCollapsed && classes.OptionsCollapsed}`}>
      <label className={classes.Option}>
          <input type="checkbox" name="coming" value="coming" onChange={(e) => updateComing(e.target.checked)} />
          <span className="checkmark"></span>
          <img src={comingSoonIconLight} alt="" className="light" />
          <img src={comingSoonIconDark} alt="" className="dark" />
          Coming soon
      </label>
      <label className={classes.Option}>
          <input type="checkbox" name="new" value="new" onChange={(e) => updateNew(e.target.checked)}/>
          <span className="checkmark"></span>
          <img src={newIconLight} alt="" className="light"/>
          <img src={newIconDark} alt="" className="dark"/>
          New
      </label>
      <label className={classes.Option}>
          <input type="checkbox" name="recentlyLet" value="recentlyLet" onChange={(e) => updateRecentlyLet(e.target.checked)}/>
          <span className="checkmark"></span>
          <img src={recentlyLetIconLight} alt="" className="light"/>
          <img src={recentlyLetIconDark} alt="" className="dark"/>
          Recently let
      </label>
      <label className={classes.Option}>
          <input type="checkbox" name="toBeDiscussed" value="toBeDiscussed" onChange={(e) => updateToBeDiscussed(e.target.checked)}/>
          <span className="checkmark"></span>
          <img src={toBeDiscussedIconLight} alt="" className="light"/>
          <img src={toBeDiscussedIconDark} alt="" className="dark"/>
          To be discussed
      </label>
      <label className={classes.Option}>
          <input type="checkbox" name="offer" value="offer" onChange={(e) => updateOffer(e.target.checked)} />
          <span className="checkmark"></span>
          <img src={underOfferIconLight} alt="" className="light" />
          <img src={underOfferIconDark} alt="" className="dark" />
          Under Offer
      </label>
      <label className={classes.Option}>
          <input type="checkbox" name="investmentPlanned" value="investmentPlanned" onChange={(e) => updateInvestmentPlanned(e.target.checked)} />
          <span className="checkmark"></span>
          <img src={investmentPlannedIconLight} alt="" className="light" />
          <img src={investmentPlannedIconDark} alt="" className="dark" />
          Investment Planned
      </label>
    </div>

    <hr className={classes.divider} />

    <div className={`${classes.Field} ${classes.Submit}`}>
      <button className={classes.Button}>
          Search
          <img src={arrowIcon} alt="Search" width={14} height={15} />
      </button>
    </div>
  </form>
};
